<template>
    <div>
        <router-view></router-view>
    </div>
</template>

<script>

export default {
	name: 'landing-view',
	components: {},

	methods: {
		isInIframe: function () {
			return window.self !== window.top;
		},
	},

	watch: {
		$route() {
			if(this.$route.meta.restrictInIframe && this.isInIframe()) {
				this.$router.push('/widget/sales')
			} 

            if(this.$route.meta.allowInIframe && !this.isInIframe()) {
				this.$router.push('/')
			} 
		}
	},

	updated () {},

	created(){},

	mounted() {
		if(this.$route.meta.restrictInIframe && this.isInIframe()) {
			this.$router.push('/widget/sales')
		} 

		if(this.$route.meta.allowInFrame && !this.isInIframe()) {
			this.$router.push('/')
		} 
	}
}

</script>